import React from 'react'


export default function About(props) {
    


  return (
  <>

  <div className={`container my-3 text-${props.mode.textcolor}`}  >
    <h1 className='my-3'>About Us</h1>
    <p>Our platform is built to make text processing simple, fast, and reliable. Whether you need a quick word count, want to convert text to uppercase or lowercase, or remove extra spaces, our tools are crafted to handle these tasks with ease. Our website’s design is intuitive, so you can access each tool without any technical expertise.</p>
    <p>
At the heart of our service is a commitment to accuracy. Every feature is designed to produce precise results, whether it's counting words for an assignment or preparing text for publication. The uppercase and lowercase conversion tools are especially useful for content creators, students, and professionals who want to ensure their text format aligns with specific guidelines.
</p>
<p>
Removing unnecessary spaces is another valuable tool, particularly for those working on technical documents, code, or formal text where spacing accuracy is critical. Extra spaces can distract from clarity and even affect the appearance of a document, so our tool provides a seamless solution.
</p>
<p>
Speed is a priority for us. Each tool delivers fast results to help you focus more on your content rather than formatting. This feature is perfect for those on tight deadlines or anyone looking to streamline their writing and editing process.
</p>
<p>
We understand that every user has unique needs, and our tools are designed to be flexible. Whether you need to format a short paragraph or a lengthy document, our platform can handle texts of various lengths without compromising performance.
</p>
<p>
Privacy is important to us. Our tools operate entirely in-browser, meaning no text data is saved or transmitted, so your content remains secure. This ensures that students, writers, and professionals can use our services with confidence.
</p>
<p>
The convenience of having multiple tools in one place means you don’t need to jump between different websites for different tasks. Our platform aims to be a one-stop solution for quick text editing and formatting.
</p>
<p>
Accessibility is also a key part of our design. Our website is compatible with both desktop and mobile devices, allowing users to work wherever they are. This adaptability ensures our tools are available whenever and wherever they’re needed.
</p>
<p>
In addition to accessibility, our tools support multiple languages, making them useful for users worldwide. We believe text processing should be available to everyone, regardless of their language or location.
</p>
<p>
Ultimately, our mission is to make text editing simple, fast, and efficient. By focusing on key features that users rely on most, we provide a platform that saves time, boosts productivity, and helps people achieve their goals.</p>

  </div>
  </>
  )
}
