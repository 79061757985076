import React from "react";


function Alert(props) {

  const capitalize = (word) => {
    const lower = word.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
}
// let mystyle = {
//   height: "50px",
//   backgrounColor: "Black"
// }

  if(props.alert === null){
    return (
      <>
      <div style={{height: "50px"}}>
        </div>
      </>
    );
  }else{
    return (
      <>
      <div style={{height: "50px"}}>
      <div className={`alert alert-${props.alert.type} alert-dismissible fade show`} role="alert">
        <strong>{capitalize(props.alert.type)}</strong>: {props.alert.msg}
        </div>
        </div>
      </>
    );
    // return (
    //   <>
    //   <div style={{height: "50px"}}></div>
    //   </>
    // );

  }
}
export default Alert;
