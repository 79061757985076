import React, {useState} from 'react'


export default function Textform(props){
    const handleupclick = ()=>{
    let newtext = text.toUpperCase();
    settext(newtext)
   props.showAlert("Converted to uppercase!" , "success")
   
}

const handleonchange = (event)=>{
    
    settext(event.target.value)
}

const handlelowerclick = ()=>{
    
    let newtext = text.toLowerCase();
    settext(newtext)
    props.showAlert("Converted to lowercase!" , "success")
 
}
const handleCopy = () => {
   
    navigator.clipboard.writeText(text);
    props.showAlert("Copied Text!" , "success")
}
const handleExtraSpaces = () => {
    let newText = text.split(/[ ]+/);
    settext(newText.join(" "))
    props.showAlert("Removed extra spaces!" , "success")
}
const handleClear = () => {
    settext("")
    props.showAlert("Cleared!" , "success")
}

    const [text, settext] = useState("");
return(
<>
<div className={`text-${props.mode.textcolor}  main`}>
<div className={`container text-${props.mode.textcolor} `} >
<h1 className='my-4'>{props.title}</h1>
<div className="mb-3">
  <textarea className={`form-control  text-${props.mode.textcolor}`} style={{backgroundColor: props.mode.bgcolor==='dark'?'#212529':'#F8F9FA'}} value={text} onChange={handleonchange} id="exampleFormControlTextarea1" rows="8"></textarea>
</div>

<button className='btn btn-primary my-1' onClick={handleupclick} >Convert to Uppercase</button>
<button className='btn btn-primary ms-1 my-1' onClick={handlelowerclick} >Convert to LowerCase</button>
<button className='btn btn-primary ms-1 my-1' onClick={handleCopy} >Copy Text</button>
<button className='btn btn-primary ms-1 my-1' onClick={handleExtraSpaces} >Remove Extra Spaces</button>
<button className='btn btn-primary ms-1 my-1' onClick={handleClear} >Clear</button>
</div>
<div className="container">
    <h1>Text Summary</h1>
    <p>{text.split(/\s+/).filter((element)=>{return element.length!==0}).length} Words and {text.length} Characters</p>
    <p>{0.008 * text.split(" ").filter((element)=>{return element.length!==0}).length} Minute read </p>
    <h2>Text Preview</h2>
    <p>{text===""?"Write somethig for preview":text}</p>
</div>
</div>

</>
);

}