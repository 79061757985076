
import './App.css';
import Alert from './components/Alert';
import About from './components/About';
import Navbar from './components/Navbar';
import Textform from './components/TextForm';
import React,{useState} from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";



function App() {

let toggle = ()=>{
if(mode.bgcolor === "light"){

  setMymode({
    bgcolor: "dark",
    textcolor: "light" 
  })
  document.body.style.backgroundColor = `#26282a`
  showAlert("Dark mode has been enable successfully" , "success")
}
else{
  
  setMymode({
    bgcolor: "light",
    textcolor: "dark" 
  })
  document.body.style.backgroundColor = `white`
  showAlert("Light mode has been enable successfully" , "success")
}
}

let showAlert = (message, type)=>{
setAlert({
  msg: message,
  type: type
  
})
setTimeout(() => {
  setAlert(null)
}, 2000);

}

const [alert, setAlert] = useState(null)
 
  const [mode, setMymode] = useState({
    bgcolor: "light",
    textcolor: "dark" 
  })
  
  return (
  <>
<Router>
<Navbar title = "TextUtils" about = "About Us" toggle={toggle}  mode={mode}/>
<Alert alert= {alert}/>
<Routes>
          <Route path="/" element={<Textform title="TextUtils - Smart, Simple Text Tools" showAlert = {showAlert} mode={mode}/>}></Route>
          <Route path="/about" element={<About mode = {mode}/>}></Route>
</Routes>
</Router>
  </>
  );
}

export default App;
